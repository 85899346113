<script>
  import Gallery from "svelte-gallery";
  import Asterism from "./Asterism.svelte";
  import Card from "./Card.svelte";

  import Image from "svelte-image";

  import useMediaQuery from "./useMQ.js";

  const images = [
    { src: "/g/005-800.webp", height: 1108, width: 1716 },
    { src: "/g/16-800.webp", height: 1116, width: 1552 },
    { src: "/g/14-800.webp", height: 4432, width: 6473 },
    { src: "/g/15-800.webp", height: 6452, width: 4432 },
    { src: "/g/006-800.webp", height: 2162, width: 1430 },
    { src: "/g/13-800.webp", height: 1853, width: 3036 },
    { src: "/g/kontich-00-800.webp", height: 3018, width: 4024 },
    { src: "/g/kontich-02-800.webp", height: 4032, width: 3024 },
    { src: "/g/02-800.webp", height: 2243, width: 3214 },
    { src: "/g/002-800.webp", height: 1981, width: 2637 },
    { src: "/g/leuven-74-07-800.webp", height: 955, width: 1295 },
    { src: "/g/leuven-74-08-800.webp", height: 1228, width: 1790 },
    { src: "/g/leuven-74-01-800.webp", height: 1127, width: 1617 },
    { src: "/g/leuven-74-02-400.webp", height: 1130, width: 788 },
    { src: "/g/leuven-74-03-400.webp", height: 1121, width: 780 },
    { src: "/g/leuven-74-04-800.webp", height: 1084, width: 1556 },
    { src: "/g/leuven-74-06-800.webp", height: 1060, width: 1610 },
    { src: "/g/03-800.webp", height: 4480, width: 6688 },
    { src: "/g/003-800.webp", height: 2660, width: 2624 },
    { src: "/g/004-800.webp", height: 2644, width: 2656 },
    { src: "/g/05-800.webp", height: 4272, width: 6528 },
    { src: "/g/d-03-800.webp", height: 3478, width: 2194 },
    { src: "/g/d-06-800.webp", height: 2391, width: 1591 },
    { src: "/g/04-800.webp", height: 4272, width: 6512 },
    { src: "/g/06-800.webp", height: 6512, width: 4272 },
    { src: "/g/07-800.webp", height: 4368, width: 6592 },
    { src: "/g/19-800.webp", height: 4528, width: 6560 },
    { src: "/g/17-800.webp", height: 4528, width: 6816 },
    { src: "/g/007-800.webp", height: 4496, width: 6640 },
    { src: "/g/d-02-800.webp", height: 3902, width: 2472 },
    { src: "/g/08-800.webp", height: 4336, width: 6512 },
    { src: "/g/e-05-800.webp", height: 2297, width: 1654 },
    { src: "/g/e-06-800.webp", height: 5184, width: 3360 },
    { src: "/g/e-07-800.webp", height: 2053, width: 2943 },
    { src: "/g/d-04-800.webp", height: 2282, width: 2888 },
    { src: "/g/e-10-800.webp", height: 6288, width: 4320 },
    { src: "/g/e-11-800.webp", height: 4336, width: 6352 },
    { src: "/g/18-800.webp", height: 4336, width: 6240 },
    { src: "/g/e-08-800.webp", height: 1287, width: 1597 },
    { src: "/g/e-02-800.webp", height: 1000, width: 814 },
    { src: "/g/e-04-800.webp", height: 2304, width: 3072 },
    { src: "/g/e-09-800.webp", height: 2835, width: 2126 },
    { src: "/g/e-01-800.webp", height: 2128, width: 2832 },
    { src: "/g/e-03-800.webp", height: 3178, width: 4770 },
    { src: "/g/10-400.webp", height: 724, width: 724 },
    { src: "/g/01-800.webp", height: 1143, width: 1600 },
    { src: "/g/b-02-800.webp", height: 934, width: 1246 },
    { src: "/g/b-03-800.webp", height: 3978, width: 2983 },
    { src: "/g/b-04-800.webp", height: 2976, width: 3968 },
    { src: "/g/b-05-800.webp", height: 2976, width: 3968 },
    { src: "/g/d-05-800.webp", height: 3024, width: 4032 },
    { src: "/g/b-06-800.webp", height: 3968, width: 2976 },
  ];

  let isPhone = false;
  useMediaQuery("(max-width: 600px)").subscribe((v) => (isPhone = v));
  let isTablet = false;
  useMediaQuery("(max-width: 1200px").subscribe(
    (v) => (isTablet = !isPhone && v)
  );
</script>

<div class="wrapper">
  <Gallery {images} rowHeight={176} gutter={3} />
</div>

<style>
  .wrapper {
    background-color: white;
    margin: 0;
    padding: 0;
  }
</style>
