<script lang="ts">
  import { Router, Route, Link } from "svelte-navigator";
  import Memoriam from "./Memoriam.svelte";
  import Post from "./Post.svelte";
  import Photo from "./Photo.svelte";
  import Card from "./Card.svelte";
  import HeroImage from "./HeroImage.svelte";
  import Herinneringen from "./Herinneringen.svelte";
  import Gallery from "./Gallery.svelte";
  import PhotoPrint from "./PhotoPrint.svelte";
  import Playlist from "./Playlist.svelte";
</script>

<Router>
  <Route path="print">
    <PhotoPrint />
  </Route>
  <main>
    <Route path="post">
      <Post />
    </Route>
    <Route path="photo">
      <Photo />
    </Route>
    <Route>
      <h1 class="target">&nbsp;</h1>
      <HeroImage src="/g/images/Foto-zee_bewerkt-1600.webp" />

      <!-- <Card title="Dank voor de vele warme woorden van troost" wide centered>
        <p class="thanks-body">Groeten kan vanaf zaterdag 2 april.</p>
        <p class="thanks-author">— Wouter, Pieter & familie</p>
      </Card> -->

      <Memoriam />

      <!-- <div class="cards">
        <Card title="Hier vindt u het boekje van de uitvaartdienst." monochrome>
          <a
            href="/Agnes-Stroobants_uitvaart.pdf"
            download="Agnes-Stroobants_uitvaart">boekje uitvaart downloaden</a
          >
        </Card>
      </div> -->

      <center
        ><a
          href="/Rouwbrief_Agnes Stroobants.pdf"
          download="Rouwbrief_Agnes Stroobants">rouwbrief downloaden</a
        ></center
      >

      <Herinneringen />
      <Gallery />
      <!-- <Playlist /> -->
      <div class="spacer" />
    </Route>
  </main>
</Router>

<style>
  .target {
    line-height: 0;
    height: 0;
    font-size: 0;
  }
  .thanks-body {
    line-height: 1.3em;
    font-size: 0.8em;
  }
  .thanks-author {
    font-style: italic;
    text-align: right;
  }
  main {
    margin: 0 auto;
  }
  @media (min-width: 640px) {
    main {
      max-width: 1600px;
    }
  }
  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  @media (max-width: 640px) {
    .cards {
      flex-direction: column;
      align-items: center;
    }
  }
  .spacer {
    height: 20vh;
  }
  h1:focus {
    outline: 0px dashed orange;
  }
</style>
