<script lang="ts">
  export let title;
  export let inverted = false;
  export let big = false;
  export let extraBig = false;
  export let monochrome = false;
  export let centered = false;
  export let wide = false;
</script>

<div
  class="card"
  class:inverted
  class:big
  class:extraBig
  class:monochrome
  class:centered
  class:wide
>
  <div class="border">
    <p>{title}</p>
    <slot />
  </div>
</div>

<style>
  .card {
    background-color: rgb(255, 249, 241);
    width: 300px;
    border-radius: 0px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    margin: 1rem;
    box-sizing: content-box;
    padding: 4px;
    display: flex;
  }

  .border {
    border-radius: 1px;
    border: 2px solid #555;
    padding: 1rem;
    flex-grow: 1;
  }
  p {
    color: rgb(60, 45, 89);
  }
  .centered {
    margin: 0 auto;
  }

  .monochrome p {
    color: #333;
  }
  .inverted {
    background-color: rgb(60, 45, 89);
    color: rgb(218, 198, 247);
  }
  .inverted .border {
    border: solid 1px rgb(35, 16, 70);
  }
  .inverted p {
    color: rgb(218, 198, 247);
  }
  .monochrome.inverted p {
    color: rgb(218, 198, 247);
  }
  .big {
    width: 600px;
  }
  .extraBig {
    width: 100%;
  }

  :global(.inverted a) {
    color: rgb(218, 198, 247);
  }
  .card p {
    margin: 0;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  @media (min-width: 1024px) {
    .card {
      width: 420px;
    }
    .big {
      width: 600px;
    }
    .extraBig {
      width: 100%;
    }
  }

  @media (max-width: 722px) {
    .card {
      box-sizing: border-box;
      margin: 0;
      margin-bottom: 1rem;
      width: 100%;
      flex-grow: 1;
    }
    .border {
      padding: 1.5rem;
    }
  }

  .wide {
    width: 100%;
    max-width: 600px;
    font-size: 1.2em;
  }
</style>
