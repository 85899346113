<script lang="ts">
</script>

<div class="contained">
  <slot />
</div>

<style>
  .contained {
    text-align: center;
    padding: 2rem;
    margin: 0 auto;
  }
  @media (min-width: 640px) {
    .contained {
      max-width: 800px;
    }
  }
</style>
