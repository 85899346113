<script lang="ts">
  import { Link } from "svelte-navigator";
  import Card from "./Card.svelte";
  import Asterism from "./Asterism.svelte";
  import Herinnering from "./Herinnering.svelte";

  interface Memory {
    title: string;
    body: string;
    author: string;
  }

  const url = "https://karelsoudan-api-e5osbmtmmq-ew.a.run.app/";
  const promise: Promise<Memory[]> = fetch(url).then((response) =>
    response.json()
  );

  const j = Math.random() > 0.5 ? 0 : 1;
</script>

<div style="text-align:center;">
  <Asterism />
</div>

<h2><a id="gedenkboek" />Gedenkboek</h2>

<!-- <Card
  title="Lees ook de vele reacties op berichten van Universiteit Antwerpen"
  monochrome
  centered
>
  <a
    href="https://www.linkedin.com/feed/update/urn:li:activity:6890250557991571456/?commentUrn=urn%3Ali%3Acomment%3A(activity%3A6890250557991571456%2C6890640014058102784)"
    target="_blank">ga naar bericht op LinkedIn</a
  >
  <br />
  <br />
  <a
    href="https://www.facebook.com/uantwerpenfaculteitBE/posts/5140648995967273?__cft__[0]=AZUOJjdLJdC_mR4ZSI_HCB1oXh9z2foPnxoYCUULuksltfcvZcpeMdcmihfBOtRCffZ7PP06MY2tatnrh6Fg-rUBgqb0N0lKQX3jM9DK4785_QY8T33smzh3ti_9qvNqlIZQeaX4Mm_t6otSsUjArMezyDrSHNhCEwLkOR7f6T7i4WxMqgcdJtovwoPQk-8lGLg&__tn__=%2CO%2CP-R"
    target="_blank">ga naar bericht op Facebook</a
  >
</Card> -->

<div class="cards">
  {#await promise then memories}
    {#each memories as memory, i}
      <Herinnering
        title={memory.title}
        body={memory.body}
        author={memory.author}
        inverted={(i + j) % 2 === 0}
      />
    {/each}
  {/await}
</div>
<Card title="plaats zelf een boodschap in het gedenkboek" monochrome centered>
  <Link to="/post">ga naar het formulier</Link>
</Card>

<style>
  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  h2 {
    text-align: center;
    font-weight: 400;
  }
  @media (max-width: 700px) {
    .cards {
      flex-direction: column;
      align-items: center;
    }
  }
</style>
