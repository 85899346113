<script lang="ts">
  import { Link } from "svelte-navigator";
  import Contained from "./Contained.svelte";
  import Form from "./Form.svelte";
  import Herinnering from "./Herinnering.svelte";
  import Card from "./Card.svelte";

  let loading = false;
  let done = false;
  let posted;

  async function confirmAction({ title, body, author }) {
    posted = { title, body, author };
    loading = true;
    const rawResponse = await fetch(
      "https://karelsoudan-api-e5osbmtmmq-ew.a.run.app/",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ title, body, author }),
      }
    );
    const content = await rawResponse.json();
    loading = false;

    done = true;
  }
</script>

<Contained>
  <p>in memoriam</p>
  <h1>Agnes Stroobants</h1>

  <iframe
    src="https://script.google.com/macros/s/AKfycbz_j0CcHezYrRyJhvg8Dym3WC-aXQ9ftEin4ITY4Un53eoZ8EHRH6t4iL8TU26Ulizi1w/exec"
    width="100%"
    height="800"
    frameBorder="0"
    allowfullscreen=""
    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
  />

  {#if !loading}
    <br />
    <br />
    <Link to="/#gedenkboek">terug</Link>
  {/if}
</Contained>

<style>
  p {
    font-style: italic;
  }
  h1 {
    color: #17718d;
    font-size: 2rem;
    font-weight: 400;
  }
  h1:focus {
    outline: 0px dashed orange;
  }
</style>
