<script lang="ts">
  import Image from "svelte-image";
  export let src = "";
</script>

<div alt="cross" class="hero" style="background-image: url({src});" />

<style>
  .hero {
    display: block;
    width: 100%;
    height: 67vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  @media (min-width: 640px) {
    .hero {
      height: 50vh;
    }
  }

  @media (min-width: 1600px) {
    .hero {
      height: 43vh;
    }
  }
</style>
