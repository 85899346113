<script lang="ts">
  export let loading = true;
  export let title = "";
  export let body = "";
  export let author = "";
  export let confirmAction = ({ title, body, author }) => {
    console.log({ title, body, author });
  };
</script>

<input bind:value={title} placeholder="titel" required disabled={loading} />

<textarea bind:value={body} rows="16" disabled={loading} />

<input bind:value={author} placeholder="uw naam" required disabled={loading} />

<button
  on:click={() => confirmAction({ title, body, author })}
  disabled={loading || !(body.length && author.length && title.length)}
  >versturen</button
>
{#if loading}
  <div style="text-align:center;">even geduld...</div>
{/if}

<style>
  textarea,
  input,
  button {
    display: block;
    margin: 0.5em 0;
    font-size: inherit;
  }
  textarea {
    width: 100%;
  }
  @media (max-width: 640px) {
    textarea,
    input,
    button {
      width: 100%;
    }
  }
</style>
