<p>&#8258;</p>

<style>
  p {
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
    margin: 2rem auto;
  }
</style>
