<script lang="ts">
  export let title;
</script>

<div>
  <p class="title">{title}</p>
  <div class="names">
    <slot name="names">
      <p class="missing">Unknown name</p>
    </slot>
  </div>
</div>

<style>
  .title {
    font-style: italic;
    text-align: left;
    width: 180px;
    display: block;
    margin: 0px;
  }
  .names {
    text-align: left;
    margin-left: 0px;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: 640px) {
    .title {
      text-align: right;
    }
    .names {
      margin-left: 180px;
    }
  }
</style>
