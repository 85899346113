<script lang="ts">
  import Card from "./Card.svelte";
  export let title = "title";
  export let body = "body";
  export let author = "author";
  export let inverted = false;

  let big = body.length > 600;
  let extraBig = body.length > 1200;
</script>

<Card {title} {inverted} {big} {extraBig}>
  <p class="body" class:big class:extraBig>{body}</p>
  <p class="author">— {author}</p>
</Card>

<style>
  .body {
    white-space: pre-wrap;
    line-height: 1.3em;
  }
  .author {
    font-style: italic;
    text-align: right;
  }
  .body.big {
    column-count: 2;
    font-size: 0.9rem;
  }
  .body.extraBig {
    column-count: 3;
    font-size: 0.8rem;
  }
  @media (max-width: 900px) {
    .body.extraBig {
      column-count: 2;
    }
  }
  @media (max-width: 600px) {
    .body.big {
      column-count: 1;
    }
    .body.extraBig {
      column-count: 1;
    }
  }
</style>
