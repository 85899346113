<script lang="ts">
  import { Link } from "svelte-navigator";
  import Contained from "./Contained.svelte";
  import Form from "./Form.svelte";
  import Herinnering from "./Herinnering.svelte";
  import Card from "./Card.svelte";

  let loading = false;
  let done = false;
  let posted;

  async function confirmAction({ title, body, author }) {
    posted = { title, body, author };
    loading = true;
    const rawResponse = await fetch(
      "https://karelsoudan-api-e5osbmtmmq-ew.a.run.app/",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ title, body, author }),
      }
    );
    const content = await rawResponse.json();
    loading = false;

    done = true;
  }
</script>

<Contained>
  <p>in memoriam</p>
  <h1>Agnes Stroobants</h1>

  {#if done}
    <p>Erg bedankt voor uw boodschap.</p>
    <div class="wrapper">
      <Herinnering {...posted} />
    </div>
  {:else}
    <div class="form-paper">
      <Form {confirmAction} {loading} />
    </div>
  {/if}

  <br />
  <br />

  <Card title="Heb je nog foto's van Agnes?" centered
    ><p>
      Als u die graag zou willen delen, zijn we je daar alvast erg dankbaar
      voor. Je kan die ons doorsturen via <a href="/photo">dit formulier</a>
    </p></Card
  >

  {#if !loading}
    <br />
    <br />
    <Link to="/#gedenkboek">terug</Link>
  {/if}
</Contained>

<style>
  p {
    font-style: italic;
  }
  h1 {
    font-size: 2rem;
    font-weight: 400;
  }
  h1:focus {
    outline: 0px dashed orange;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    text-align: left;
  }
  .form-paper {
    background-color: rgb(240, 229, 241);
    border-radius: 5px;
    padding: 1rem;
    border: solid 2px rgb(161, 123, 165);
  }
</style>
