<script>
  import Gallery from "svelte-gallery";
  import Asterism from "./Asterism.svelte";
  import Card from "./Card.svelte";

  import Image from "svelte-image";

  import useMediaQuery from "./useMQ.js";

  const images = [
    { src: "/g/images/foto-01-800.webp", height: 4717, width: 6867 },
    { src: "/g/images/foto-10-800.webp", height: 792, width: 1168 },
    { src: "/g/images/foto-02-400.webp", height: 960, width: 732 },
    { src: "/g/images/foto-03-400.webp", height: 960, width: 720 },
    { src: "/g/images/foto-04-800.webp", height: 960, width: 1440 },
    { src: "/g/images/foto-07-800.webp", height: 960, width: 1280 },
    { src: "/g/images/foto-12-800.webp", height: 3504, width: 1875 },
    { src: "/g/images/foto-13-800.webp", height: 2710, width: 2904 },
    { src: "/g/images/foto-08-800.webp", height: 2350, width: 3124 },
    { src: "/g/images/foto-11-400.webp", height: 531, width: 720 },
    { src: "/g/images/foto-05-400.webp", height: 618, width: 640 },
    { src: "/g/images/foto-06-800.webp", height: 1440, width: 1920 },
    { src: "/g/images/foto-09-800.webp", height: 1472, width: 3264 },
    // { src: "/g/leuven-74-07-800.webp", height: 955, width: 1295 },
    // { src: "/g/leuven-74-08-800.webp", height: 1228, width: 1790 },
    // { src: "/g/leuven-74-01-800.webp", height: 1127, width: 1617 },
    // { src: "/g/leuven-74-02-400.webp", height: 1130, width: 788 },
    // { src: "/g/leuven-74-03-400.webp", height: 1121, width: 780 },
    // { src: "/g/leuven-74-04-800.webp", height: 1084, width: 1556 },
    // { src: "/g/leuven-74-06-800.webp", height: 1060, width: 1610 },
    // { src: "/g/03-800.webp", height: 4480, width: 6688 },
    // { src: "/g/003-800.webp", height: 2660, width: 2624 },
    // { src: "/g/004-800.webp", height: 2644, width: 2656 },
    // { src: "/g/05-800.webp", height: 4272, width: 6528 },
    // { src: "/g/d-03-800.webp", height: 3478, width: 2194 },
    // { src: "/g/d-06-800.webp", height: 2391, width: 1591 },
    // { src: "/g/04-800.webp", height: 4272, width: 6512 },
    // { src: "/g/06-800.webp", height: 6512, width: 4272 },
    // { src: "/g/07-800.webp", height: 4368, width: 6592 },
    // { src: "/g/19-800.webp", height: 4528, width: 6560 },
    // { src: "/g/17-800.webp", height: 4528, width: 6816 },
    // { src: "/g/007-800.webp", height: 4496, width: 6640 },
    // { src: "/g/d-02-800.webp", height: 3902, width: 2472 },
    // { src: "/g/08-800.webp", height: 4336, width: 6512 },
    // { src: "/g/e-05-800.webp", height: 2297, width: 1654 },
    // { src: "/g/e-06-800.webp", height: 5184, width: 3360 },
    // { src: "/g/e-07-800.webp", height: 2053, width: 2943 },
    // { src: "/g/d-04-800.webp", height: 2282, width: 2888 },
    // { src: "/g/e-10-800.webp", height: 6288, width: 4320 },
    // { src: "/g/e-11-800.webp", height: 4336, width: 6352 },
    // { src: "/g/18-800.webp", height: 4336, width: 6240 },
    // { src: "/g/e-08-800.webp", height: 1287, width: 1597 },
    // { src: "/g/e-02-800.webp", height: 1000, width: 814 },
    // { src: "/g/e-04-800.webp", height: 2304, width: 3072 },
    // { src: "/g/e-09-800.webp", height: 2835, width: 2126 },
    // { src: "/g/e-01-800.webp", height: 2128, width: 2832 },
    // { src: "/g/e-03-800.webp", height: 3178, width: 4770 },
    // { src: "/g/10-400.webp", height: 724, width: 724 },
    // { src: "/g/01-800.webp", height: 1143, width: 1600 },
    // { src: "/g/b-02-800.webp", height: 934, width: 1246 },
    // { src: "/g/b-03-800.webp", height: 3978, width: 2983 },
    // { src: "/g/b-04-800.webp", height: 2976, width: 3968 },
    // { src: "/g/b-05-800.webp", height: 2976, width: 3968 },
    // { src: "/g/d-05-800.webp", height: 3024, width: 4032 },
    // { src: "/g/b-06-800.webp", height: 3968, width: 2976 },
  ];

  let isPhone = false;
  useMediaQuery("(max-width: 600px)").subscribe((v) => (isPhone = v));
  let isTablet = false;
  useMediaQuery("(max-width: 1200px").subscribe(
    (v) => (isTablet = !isPhone && v)
  );
</script>

<div style="text-align:center;">
  <Asterism />
</div>

<h2><a id="fotoboek" /></h2>
<!-- <Image src="images/foto-01.jpg" />
<Image src="images/foto-02.jpeg" />
<Image src="images/foto-03.jpeg" />
<Image src="images/foto-04.jpeg" />
<Image src="images/foto-05.jpeg" />
<Image src="images/foto-06.jpg" />
<Image src="images/foto-07.JPG" />
<Image src="images/foto-08.JPG" />
<Image src="images/foto-09.jpg" />
<Image src="images/foto-10.jpg" /> -->

<Gallery
  {images}
  rowHeight={isPhone ? 220 : isTablet ? 300 : 400}
  gutter={isPhone ? 2 : isTablet ? 4 : 8}
/>

<br />

<Card title="Heb je nog foto's van Agnes?" centered
  ><p>
    Als u die graag zou willen delen, zijn we je daar alvast erg dankbaar voor.
    Je kan die ons doorsturen via <a href="/photo">dit formulier</a>
  </p></Card
>

<style>
  h2 {
    text-align: center;
    font-weight: 400;
  }
</style>
