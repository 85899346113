<script lang="ts">
</script>

<img src="/cross.svg" alt="cross" class="cross" />

<style>
  .cross {
    width: 3rem;
    height: 3rem;
    margin: 3rem auto;
  }
</style>
