<script lang="ts">
  import Family from "./Family.svelte";
  import Cross from "./Cross.svelte";
  import Contained from "./Contained.svelte";
  import Image from "svelte-image";
</script>

<Contained>
  <Cross />
  <quote>
    <em
      >Er is nog zomer en genoeg.
      <em
        >Wat zou het loodzwaar tillen zijn
        <em
          >wat een gezwoeg
          <em
            >als iedereen niet iedereen
            <em
              >ter wille was
              <em
                >als iedereen niet iedereen
                <em>op handen droeg. </em>
              </em>
            </em>
          </em>
        </em>
      </em>
    </em>
    <p class="author">(Judith Herzberg)</p>
  </quote>

  <h1>Agnes Stroobants</h1>

  <!-- <h4 />

  <div class="image-wrapper">
    <img src="/g/prentje-karelsoudan-800.webp" width="200" />
  </div> -->

  <div>
    <p class="maxed">
      Geboren te Leuven op 28 januari 1958 en na een bijzonder moedige strijd
      thuis overleden te Leuven op 31 maart 2022
    </p>
  </div>

  <div>
    <Family title="echtgenote van">
      <span slot="names">
        <div class="name">
          <p class="name">Jan Rottier</p>
        </div>
      </span>
    </Family>
    <Family title="Moeke van">
      <span slot="names">
        <div class="name">
          <p class="name">Nele Van Bouwel</p>
          <p class="name indent">Flo, Janne</p>
        </div>
        <div class="name">
          <p class="name">Katrijn en Wouter Van Bouwel - Soudan</p>
          <p class="name indent">Winter Faust, Ada †, Swaene, IJsbrand</p>
        </div>

        <div class="name">
          <p class="name">Gijs en Sara Van Bouwel - Dehaes</p>
          <p class="name indent">Illian, Hanne, Kasper</p>
        </div>

        <div class="name">
          <p class="name">Fien en Jan Van Bouwel – Lammers</p>
        </div>

        <div class="name">
          <p class="name">Saskia en Jan Rottier - Heylen</p>
        </div>
      </span>
    </Family>

    <Family title="zus van">
      <span slot="names">
        <p class="name">Jos Stroobants</p>
        <p class="name">Luc en Ann Stroobants - Snijders</p>
        <p class="name">Chris en Daniel Stroobants - De Vadder</p>
      </span>
    </Family>
  </div>

  <br />

  <p>
    We nodigen je uit om samen afscheid te nemen in de <a
      href="https://www.google.com/maps/place/Sint-Antoniuskerk/@50.872177,4.5735429,11z/data=!4m9!1m2!2m1!1sparochiekerk+sint-antonius+kessel-lo!3m5!1s0x47c1612b2c25d157:0xcd5b90771b2d67f8!8m2!3d50.872216!4d4.7135224!15sCiRwYXJvY2hpZWtlcmsgc2ludC1hbnRvbml1cyBrZXNzZWwtbG-SAQ9jYXRob2xpY19jaHVyY2g"
      target="_blank"
      >parochiekerk Sint-Antonius, Léon Schreursvest 33, 3001 Leuven</a
    >, op donderdag 7 april 2022 om 11 uur.
  </p>

  <p class="small">
    Geen traditionele begrafeniskledij, noch kransen; Moeke hield immers van het
    leven, kleur en wilde bloemen. Deel je mooiste verhalen, fijnste anekdotes
    of warme herinneringen met ons tijdens de receptie na de plechtigheid. Kan
    je er niet bij zijn, dan zijn je woorden welkom op www.AgnesStroobants.be.
  </p>

  <p class="small">
    Welkom thuis voor een laatste groet, gedicht, of teder gebaar op zaterdag 2
    april van 14 u tot 19 u met open kist of op zondag 3 april van 14 u tot 17 u
    met gesloten kist.
  </p>

  <p class="small">
    Rouwadres: Joris Helleputteplein 4 bus 00.01, 3000 Leuven (thuis)
  </p>
</Contained>

<style>
  quote {
    font-style: italic;
    text-align: left;
    display: block;
    max-width: 420px;
    margin: 0 auto;
    line-height: 1.2em;
  }
  quote em {
    display: block;
    margin-left: 0.15em;
  }
  quote .author {
    text-align: right;
    font-size: 0.75em;
  }

  @media (min-width: 640px) {
    quote {
      max-width: 420px;
    }
    quote em {
      display: block;
      margin-left: 0.5em;
    }
  }

  h1 {
    font-size: 4rem;
    font-weight: 400;
    margin-bottom: 0.2em;
  }

  @media (max-width: 640px) {
    h1 {
      font-size: 3rem;
    }
  }

  h1:focus {
    outline: 0px dashed orange;
  }

  h4 {
    font-weight: 300;
    margin-top: 0;
  }
  .maxed {
    max-width: 540px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .name {
    margin: 0;
    padding-bottom: 0.35em;
  }
  .indent {
    padding-left: 2rem;
  }
  .small {
    margin-top: 2rem;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.7);
  }
  .image-wrapper {
    max-width: 200px;
    margin: 1rem auto;
    border: solid 2px #000;
    padding: 2px;
    font-size: 0;
  }
  :global(.main-image) {
    max-width: 200px;
  }
</style>
